import { ModalProvider } from '@shiftsmartinc/react-hooks';

export interface GlobalContextProps {
  children: React.ReactElement;
}

/**
 * @name GlobalContext
 * @description TODO: Add a description
 */
export const GlobalContext = (props: GlobalContextProps) => {
  const { children } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return <ModalProvider>{children}</ModalProvider>;
};
