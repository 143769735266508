import { IUser } from '@shiftsmartinc/shiftsmart-types';
import { ToastContainer } from 'react-toastify';
import { GlobalContext } from '~/global/components/GlobalContext';
import { GlobalNavigationSidebar } from '~/global/components/GlobalNavigationSidebar';

export interface GlobalApplicationProps {
  children: React.ReactElement;
  user?: IUser;
}

/**
 * @name GlobalApplication
 * @description TODO: Add a description
 */
export const GlobalApplication = (props: GlobalApplicationProps) => {
  const { children, user } = props;

  // Hooks

  // Setup
  const isAuthenticated = !!user?.uuid;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <>
      <GlobalContext>
        <div className="flex h-screen">
          {isAuthenticated && <GlobalNavigationSidebar />}
          <div className="flex-1">{children}</div>
        </div>
      </GlobalContext>
      <ToastContainer />
    </>
  );
};
